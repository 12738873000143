import React from 'react';
import { Link, graphql } from 'gatsby';
import styled from '@emotion/styled';
import GatsbyImage from 'gatsby-image';
import { css } from '@emotion/react';
import { orderBy } from 'lodash';

const JobListItem = ({ url, job: { name, location, team }, properties: { slug }}) => (
  <Link to={url} className="list-group-item list-group-item-action row no-gutters py-3">
    <div className="col-xl-8 col-lg-7 col-md-6">
      <h5 className="mb-0">{name}</h5>
    </div>
    <div className="col-md">
      <span>{team}</span>
    </div>
    <div className="col-md">
      <span>{location}</span>
    </div>
  </Link>
);

export default function Jobs({ jobs, elements }) {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-10">
          <h2 className="h1 mb-4">{elements.tableTitle}</h2>
          <div className="row no-gutters mb-3 d-none d-md-flex">
            <div className="col-xl-8 col-lg-7 col-md-6">
              <span className="text-small text-muted">{elements.tableHeaderPosition}</span>
            </div>
            <div className="col">
              <span className="text-small text-muted">{elements.tableHeaderTeam}</span>
            </div>
            <div className="col">
              <span className="text-small text-muted">{elements.tableHeaderLocation}</span>
            </div>
          </div>
          <div className="list-group list-group-flush">
            {jobs.map(({ properties, job, url }) => <JobListItem key={url} url={url} properties={properties} job={job} />)}
          </div>
          <span className="d-block mt-4" dangerouslySetInnerHTML={{ __html: elements.tableFooter }} />
        </div>
      </div>
    </div>
  );
}

export const query = graphql`
  fragment Jobs on WpJob {
    properties {
      slug
      title
    }
    job {
      name
      location
      team
    }
  }
`;