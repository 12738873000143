import React, { Fragment } from 'react';
import Divider from './Divider';

export default function Hero({ divider = { variant: 5 }, children, title, subtitle }) {
  return (
    <section className="HeroSection bg-primary-3 header-inner o-hidden">
      {children && (
        <Fragment>
          {children}
          <hr className="border-light m-0 opacity-60" />
        </Fragment>
      )}
      <div className="container">
        <div className="row py-6 text-center justify-content-center align-items-center layer-2">
          <div className="col-xl-10 col-lg-12">
            <h1 className="display-4 text-light">{title}</h1>
            {subtitle &&
              <div className="lead mb-0 text-light" dangerouslySetInnerHTML={{ __html: subtitle }} />
            }
          </div>
        </div>
      </div>
      <Divider {...divider} />
    </section>
  );
}