import React from 'react';
import Header from '@components/Header';
import { Link, graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import Page from '@components/Page';
import Footer from '@components/Footer';
import Jobs from '@components/Jobs';
import BackgroundImage from 'gatsby-background-image';
import Hero from '@components/theme/Hero';
import { getPageElements } from '@lib/utils';
import { getJobUrl } from '../lib/urls';

export default function JobsPage({
  data: {
    page,
    jobs
  },
  pageContext: { language, refs, layouts, configuration }
}) {
  const elements = getPageElements(page);
  return (
    <Page>
      <Hero title={page.properties.title}>
        <Header
          language={language}
          refs={refs}
          layouts={layouts}
        />
      </Hero>
      <div className="container my-5">
        <Jobs elements={elements} jobs={jobs.nodes.map(job => Object.assign({}, job, { url: getJobUrl({ parent: page, language, page: job }) }))} />
      </div>
      <Footer
        configuration={configuration}
        language={language}
        layouts={layouts}
      />
    </Page>
  );
}

export const query = graphql`
  query($id: String, $lang: WpLanguageCodeEnum) {
    page: wpWebsite(id: { eq: $id }) {
      ...Page
    }
    jobs: allWpJob(filter: { language: {code: {eq: $lang}}}) {
      nodes {
        ...Jobs
      }
    }
  }
`;