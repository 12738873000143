import React from 'react';
import Divider1 from '../../../theme/leap/dist/assets/img/dividers/divider-1.svg';
import Divider2 from '../../../theme/leap/dist/assets/img/dividers/divider-2.svg';
import Divider3 from '../../../theme/leap/dist/assets/img/dividers/divider-3.svg';
import Divider4 from '../../../theme/leap/dist/assets/img/dividers/divider-4.svg';
import Divider5 from '../../../theme/leap/dist/assets/img/dividers/divider-5.svg';
import DividerV1 from '../../../theme/leap/dist/assets/img/dividers/divider-vertical-1.svg';
import DividerV2 from '../../../theme/leap/dist/assets/img/dividers/divider-vertical-2.svg';

const dividers = {
  horisontal: [Divider1, Divider2, Divider3, Divider4, Divider5],
  vertical: [DividerV1, DividerV2]
};

export default function Divider({ variant, flip, reflect, vertical, backgroundClass = 'bg-white' }) {
  const dividerClass = 'divider' + (flip ? vertical ? ' flip-x' : ' flip-y' : '') + (reflect ? vertical ? ' flip-y' : ' flip-x' : '');
  let DividerVariant = dividers[vertical ? 'vertical' : 'horisontal'][variant - 1];
  return (
    <div className={dividerClass}>
      <DividerVariant className={backgroundClass} alt="divider graphic" />
    </div>
  );
}